<template>
    <component :is="componentType" :href="linkHref" :target="linkTarget"
        class="inline-block relative pt-2 pb-1 px-4 overflow-hidden group/button focus:outline-2 focus:outline-offset-4 disabled:cursor-not-allowed"
        :type="buttonType" :class="cssClassButton">
        <span class="absolute w-full h-full left-0 top-full group-hover/button:top-0 group-focus/button:top-0 transition-[top] z-0"
            :class="cssClassBackground">
        </span>
        <span class="relative z-1">
            <slot> Button Text </slot>
        </span>
        <span v-if="icon" >            
                <slot name="icon"></slot>
        </span>
    </component>
</template>

<script>
  export default {
      name: 'IntNavButton',
    
      props: {
          type: {
              type: String,
              default: 'button',
              validator(value) {
                  return ['button', 'submit', 'anchor'].includes(value)
              }
          },
          color: {
              type: String,
              default: 'primary'
          },
          href: {
              type: String
          },
          target: {
              type: String
          },
          fitContent: { type: Boolean, default: false },
          boldText: { type: Boolean, default: false },
          fullBorder: { type: Boolean, default: false },
          bottomBorder: { type: Boolean, default: false },
          backgroundOpacity: { type: Number },
          icon: { type: String }
      },
    
      computed: {
          componentType() {
              if (this.type === 'anchor') return 'a'
  
              return 'button'
          },
          buttonType() {
              if (this.type === 'anchor') return null
  
              return this.type
          },
          linkHref() {
              if (this.type === 'anchor') return this.href
  
              return null
          },
          linkTarget() {
              if (this.type === 'anchor') return this.target
  
              return null
          },
          cssClassButton() {
              let classList = []
  
              if (this.fitContent) classList.push('w-full')
              if (this.boldText) classList.push('font-semibold text-gray-700 h-12')
            
              if (this.fullBorder) classList.push('border-t border-b border-l border-r')
              else if (this.bottomBorder) classList.push('border-[1px] border-x-0 border-t-0')
            
              if (this.color === 'inversed') classList.push('border-white bg-primary focus:outline-white')
              if (this.color === 'tint') classList.push('bg-primary focus:outline-white')
              if (this.color === 'primary') classList.push('border-primary bg-white border-b focus:outline-primary')
            
              if (this.backgroundOpacity) classList.push(`bg-opacity-${this.backgroundOpacity}`)
            
            return classList.join(' ')
          },
          cssClassBackground() {
              switch (this.color) {
                  case "inversed": return "bg-white";
                  case "tint": return "bg-stone-700 bg-opacity-40";
                  default: return "bg-primary";
              }
          }
      }
  }
</script>