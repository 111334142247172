<template>
  <nav v-if="hasLinks" class="bg-primary absolute -top-full -left-full focus-within:top-0 focus-within:left-0 w-full z-[55]" :aria-label="skipLabel">
    <div class="flex py-3 px-6">
      <span id="skip-links">{{ skipToLabel }}:</span>

      <div>
        <a v-for="(link, index) in links"
           :key="'skiplink-'+ index"
           :id="'skiplink-'+ index"
           :href="link.href"
           :target="link.target"
           class="ml-4 text-stone-900 focus-within:text-black focus-within:underline hover:text-black hover:underline"
           :aria-labelledby="'skip-links skiplink-' + index">
          {{ link.title }}
        </a>
      </div>
    </div>
  </nav>
</template>

<script>
  export default {
    name: 'IntSkipLinks',
  
    props: {
      links: { type: Array },
      skipLabel: { type: String, default: 'Skip links' },
      skipToLabel: { type: String, default: 'Skip to' }
    },
  
    data() {
      return {
        hasLinks: this.links && this.links.length > 0
      }
    }
  }
</script>