<template>
  <header id="page-header" class="sticky top-0 bg-white z-100" :class="['h-[75px] transition-[height]', isReduced ? 'md:h-[75px]': 'md:h-[107px]']">
    <div id="headerDiv" ref="headerDiv">
      <Popover ref="headerPadding" :class="['2xl:container', 'mx-auto', 'px-5', 'lg:px-8', 'xl:px-10', isReduced ? 'py-4' : 'py-4 md:py-8', 'transition-[padding]']">
        <div class="grid grid-cols-12 gap-0">
          <div class="col-span-4 lg:col-span-2 flex items-center flex-row">
            <button class="p-2 justify-center justify-self-center lg:hidden group"
                    :class="{ opened: mobileMenuOpen }"
                    @click="mobileMenuOpen = !mobileMenuOpen">
              <span class="block group-[.opened]:hidden">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" fill="#a5a5a5" height="16">
                      <use :href="iconHref('OpenMenuIcon')" />
                  </svg>
              </span>

              <span class="hidden group-[.opened]:block">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" fill="#a5a5a5" height="16">
                      <use :href="iconHref('CloseMenuIcon')" />
                  </svg>
              </span>
            </button>

            <a :href="homepageUrl" class="mx-2 lg:mx-0">
              <Logo class="w-14 lg:w-16" :class="[isReduced ? 'lg:w-30' : 'lg:w-24', 'transition-[width]']"></Logo>
            </a>
          </div>

          <div class="hidden lg:flex col-span-8 items-center pt-[3px]">
            <div class="text-center w-full">
              <PopoverGroup v-for="page in pages" class="inline-block px-3 pl-0">
                <Popover v-if="(page.subNavigations && page.subNavigations.length > 0) || (page.promos && page.promos.length > 0)" class="group">
                  <PopoverButton class="uppercase menu-item text-sm lg:text-md xl:text-base hover:underline focus:underline outline-none relative h-10">
                    {{ page.title }}<span class="arrow hidden lg:group-data-[headlessui-state=open]:inline-block">
                      <svg class="absolute -bottom-2.5 left-1/2 transform -translate-x-1/2 z-30"
                           style="right: 2.3rem;"
                           xmlns="http://www.w3.org/2000/svg"
                           width="38"
                           height="22"
                           viewBox="0 0 38 22">
                          <defs>
                              <filter :id="page.title" x="0" y="0" width="38" height="22"
                                      filterUnits="userSpaceOnUse">
                                  <feOffset dy="-2" input="SourceAlpha" />
                                  <feGaussianBlur stdDeviation="1" result="blur" />
                                  <feFlood flood-opacity="0.161" />
                                  <feComposite operator="in" in2="blur" />
                                  <feComposite in="SourceGraphic" />
                              </filter>
                          </defs>
                        
                          <g transform="matrix(1, 0, 0, 1, 0, 0)" :filter="`url(#${page.title})`">
                              <path :id="page.title" data-name="Polygon 1" d="M16,0,32,16H0Z" transform="translate(3 5)" fill="#fff" />
                          </g>
                      </svg>
                    </span>
                  </PopoverButton>

                  <PopoverOverlay class="fixed z-1 inset-0 bg-black opacity-30" :class="['top-[75px]', isReduced ? 'md:top-[75px]' : 'md:top-[107px]']" />

                  <Transition enter-active-class="transition duration-200"
                              enter-from-class="opacity-0"
                              enter-to-class="opacity-100"
                              leave-active-class="transition duration-200"
                              leave-from-class="opacity-100"
                              leave-to-class="opacity-0">
                    <PopoverPanel class="md:absolute md:left-0 w-full py-16 bg-white shadow-md z-20 mt-2">
                      <IntMegaDropdown :sub-navigations="page.subNavigations"
                                       :promos="page.promos">
                      </IntMegaDropdown>
                    </PopoverPanel>
                  </Transition>
                </Popover>
                <a v-else :href="page.url" :target="page.target"
                   class="uppercase menu-item text-sm lg:text-md xl:text-base hover:underline focus:underline outline-none relative h-10">
                  {{ page.title }}
                </a>
              </PopoverGroup>
            </div>
          </div>

          <div class="col-span-8 lg:col-span-2 text-right items-center flex-row">
            <PopoverGroup v-if="searchPage" class="inline-block">
              <Popover class="inline-block group">
                <PopoverButton class="active-blue inline-block h-10 w-10" @click="closeMenu">
                  <svg xmlns="http://www.w3.org/2000/svg"
                       class="group-data-[headlessui-state=open]:fill-blue-600 hover:fill-blue-600 w-[18px] h:[18px] sm:w-5 sm:h-5 inline-block"
                       width="20.49"
                       height="20.592"
                       fill="#a5a5a5">
                    <use :href="iconHref('SearchIcon')" />
                  </svg>

                  <span class="sr-only">{{ searchLabel }}</span>
                </PopoverButton>

                <PopoverOverlay class="fixed z-1 inset-0 bg-black opacity-30" :class="['top-[75px]', isReduced ? 'md:top-[75px]' : 'md:top-[107px]']" />

                <Transition>
                  <PopoverPanel class="absolute w-full left-0 bg-white p-5 py-14 text-center" focus>
                    <form class="w-full xl:w-4/6 inline-block" :action="searchPage" method="get">
                      <div class="flex">
                        <div class="relative w-full">
                          <input type="search"
                                 name="searchTerm"
                                 class="block p-3 text-base w-full z-20 bg-gray-100 rounded-lg border-2 border-gray-100 focus:outline-none focus:ring-blue focus:border-blue"
                                 :placeholder="searchPlaceholder"
                                 required>
                          <button type="submit" class="absolute top-1 right-1 p-3 bg-gray-100 rounded-r-lg">
                            <svg xmlns="http://www.w3.org/2000/svg"
                                 class="hover:fill-blue-600"
                                 width="20.49"
                                 height="20.592"
                                 fill="#a5a5a5">
                              <use :href="iconHref('SearchIcon')" />
                            </svg>

                            <span class="sr-only">{{ searchLabel }}</span>
                          </button>
                        </div>
                      </div>
                    </form>
                  </PopoverPanel>
                </Transition>
              </Popover>
            </PopoverGroup>

            <a v-if="contactPage" :href="contactPage" class="p-2 ml-2 lg:ml-0 xl:ml-2 active-blue focus:outline-black focus:outline-auto">
              <svg xmlns="http://www.w3.org/2000/svg"
                   class="group-data-[headlessui-state=open]:fill-blue-600 hover:fill-blue-600 w-[18px] h:[18px] sm:w-5 sm:h-5 inline-block"
                   width="20.49"
                   height="20.592"
                   fill="#a5a5a5">
                <use :href="iconHref('PhoneIcon')" />
              </svg>

              <span class="sr-only">{{ contactLabel }}</span>
            </a>

            <PopoverGroup v-if="showAccountPanel" class="inline-block">
              <Popover class="inline-block lg:relative group">
                <PopoverButton class="h-10 w-10 ml-2 lg:ml-0 xl:ml-2 active-blue" @click="closeMenu">
                  <svg xmlns="http://www.w3.org/2000/svg"
                       class="w-[15px] h:[18px] sm:w-[17px] sm:h-5 inline-block group-data-[headlessui-state=open]:fill-blue-600 hover:fill-blue-600"
                       width="20.49"
                       height="20.592"
                       fill="#a5a5a5">
                    <use :href="iconHref('UserIcon')" />
                  </svg>

                  <span class="sr-only">{{ accountButtonLabel }}</span>
                </PopoverButton>

                <PopoverOverlay class="fixed z-1 inset-0 bg-black opacity-30" :class="['top-[75px]', isReduced ? 'md:top-[75px]' : 'md:top-[107px]']" />

                <Transition>
                  <PopoverPanel v-slot="{ close }"
                                class="w-full flex flex-row justify-center items-center lg:w-auto lg:text-right left-0 lg:left-auto absolute lg:-right-10 mt-10 md:mt-14 lg:mt-3 px-6 lg:px-0">
                    <div class="relative w-96 p-8 bg-white rounded-3xl shadow-md">
                      <svg class="hidden -top-[20px] lg:inline-block lg:absolute lg:group-data-[headlessui-state=open]:inline-block"
                           style="right: 2.52rem;"
                           xmlns="http://www.w3.org/2000/svg"
                           width="38"
                           height="22"
                           viewBox="0 0 38 22">
                        <defs>
                          <filter id="Polygon_1" x="0" y="0" width="38" height="22"
                                  filterUnits="userSpaceOnUse">
                            <feOffset dy="-2" input="SourceAlpha" />
                            <feGaussianBlur stdDeviation="1" result="blur" />
                            <feFlood flood-opacity="0.161" />
                            <feComposite operator="in" in2="blur" />
                            <feComposite in="SourceGraphic" />
                          </filter>
                        </defs>

                        <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Polygon_1)">
                          <path id="Polygon_1-2" data-name="Polygon 1" d="M16,0,32,16H0Z" transform="translate(3 5)" fill="#fff" />
                        </g>
                      </svg>

                      <button @click="closePopover(close)">
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="#A5A5A5">
                          <use :href="iconHref('PlusIcon')" />
                        </svg>

                        <span class="sr-only">{{ closeLabel }}</span>
                      </button>

                      <h6 v-if="accountTitle" class="text-center text-2xl mb-4">{{ accountTitle }}</h6>

                      <div v-if="hasAccountLinks">
                        <ul>
                          <li v-for="(link, index) in accountLinks" :key="'accountlink' + index">
                            <a :href="link.url" :target="link.target" class="block py-2 text-left text-gray hover:text-black">
                              {{ link.name }}
                            </a>
                          </li>
                        </ul>
                      </div>

                      <div v-if="loginPage || accountFooterPage">
                        <int-nav-button v-if="loginPage" type="anchor" :href="loginPage" color="tint" fit-content
                                        class="text-center uppercase hover:no-underline focus:no-underline focus:outline-black">
                          {{ loginPageTitle }}
                        </int-nav-button>

                        <div class="border-t-1 mt-10 border-blue" v-if="accountFooterPage">
                          <h6 v-if="accountFooterTitle" class="text-center text-lg mb-2">{{ accountFooterTitle }}</h6>

                          <int-nav-button type="anchor" :href="accountFooterPage" color="tint" fit-content
                                          class="text-center uppercase hover:no-underline focus:no-underline focus:outline-black">
                            {{ accountFooterPageTitle }}
                          </int-nav-button>
                        </div>
                      </div>
                    </div>
                  </PopoverPanel>
                </Transition>
              </Popover>
            </PopoverGroup>

            <IntWishlistAccount v-if="showWishlistPanel" header-id="headerDiv"
                                :wishlist-list-url="wishlistListUrl" :wishlist-detail-url="wishlistDetailUrl"
                                :header-is-reduced="isReduced">
            </IntWishlistAccount>
            <IntWishlistPopup v-if="showWishlistPanel" :wishlist-list-url="wishlistListUrl" :wishlist-detail-url="wishlistDetailUrl"></IntWishlistPopup>
          </div>
        </div>
      </Popover>

      <Popover v-if="mobileMenuOpen" class="relative top-0 h-screen w-full lg:hidden bg-white">
        <PopoverGroup>
          <Transition enter-active-class="transition duration-200"
                      enter-from-class="opacity-0"
                      enter-to-class="opacity-100"
                      leave-active-class="transition duration-200"
                      leave-from-class="opacity-100"
                      leave-to-class="opacity-0">
            <PopoverPanel static class="panel relative h-screen">
              <Popover v-for="page in pages"
                       class="popover bg-white data-[headlessui-state=open]:absolute data-[headlessui-state=open]:z-50 data-[headlessui-state=open]:top-0 data-[headlessui-state=open]:w-full data-[headlessui-state=open]:min-h-screen ">
                <PopoverGroup v-if="page.subNavigations && page.subNavigations.length > 0" class="popovergroup relative w-full block">
                  <PopoverButton class="popoverbutton w-full block uppercase w-100 px-5 border-t-[1px] border-b-[1px] border-sand text-left py-3">{{ page.title }}
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" class="inline float-right rotate-180">
                      <use :href="iconHref('ChevronLeftIcon')" />
                    </svg>
                  </PopoverButton>

                  <Transition enter-active-class="transition duration-200"
                              enter-from-class="opacity-0"
                              enter-to-class="opacity-100"
                              leave-active-class="transition duration-200"
                              leave-from-class="opacity-100"
                              leave-to-class="opacity-0">
                    <PopoverPanel class="popoverpanel absolute top-0 w-full block bg-white h-screen" v-slot="{ close }">
                      <button class="w-100 px-5 block border-t-[1px] border-b-[1px] w-full uppercase border-sand text-left py-3 align-middle"
                              @click="closePopover(close)">
                        <span class="leading-none">
                          <span class="relative -top-[2px] mr-3">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" class="inline">
                              <use :href="iconHref('ChevronLeftIcon')" />
                            </svg>
                          </span>
                          
                          <span>BACK</span>
                        </span>
                      </button>

                      <IntMobileNavigation v-for="subNav in page.subNavigations"
                                           :title="subNav.title"
                                           :links="subNav.links">
                      </IntMobileNavigation>
                    </PopoverPanel>
                  </Transition>
                </PopoverGroup>
                <a v-else-if="page.url" :href="page.url" :target="page.target" class="block uppercase px-5 border-y-[1px] py-3">
                  {{ page.title }}
                </a>
              </Popover>
            </PopoverPanel>
          </Transition>
        </PopoverGroup>
      </Popover>
    </div>
  </header>

  <IntWishlistModal :wishlist-list-url="wishlistListUrl" :wishlist-detail-url="wishlistDetailUrl"></IntWishlistModal>

  <div v-if="$slots.breadcrumb" :class="['hidden lg:block absolute left-0 w-full z-40 transition-top', isReduced ? 'top-[75px]' : 'top-[107px]']">
    <slot name="breadcrumb"></slot>
  </div>
</template>

<script setup>
  import {
    PopoverGroup,
    Popover,
    PopoverOverlay,
    PopoverButton,
    PopoverPanel
  } from '@headlessui/vue'
</script>

<script>
  import ChevronLeftIcon from '../../../assets/icons/chevron-left.svg'
  import CloseMenuIcon from '../../../assets/icons/close-menu.svg'
  import HeartIcon from '../../../assets/icons/heart-icon.svg'
  import OpenMenuIcon from '../../../assets/icons/open-menu.svg'
  import PhoneIcon from '../../../assets/icons/phone-icon.svg'
  import PlusIcon from '../../../assets/icons/plus-icon.svg'
  import SearchIcon from '../../../assets/icons/search-icon.svg'
  import UserIcon from '../../../assets/icons/user-icon.svg'
  
  const iconMap = {
    'ChevronLeftIcon': ChevronLeftIcon,
    'CloseMenuIcon': CloseMenuIcon,
    'HeartIcon': HeartIcon,
    'OpenMenuIcon': OpenMenuIcon,
    'PhoneIcon': PhoneIcon,
    'PlusIcon': PlusIcon,
    'SearchIcon': SearchIcon,
    'UserIcon': UserIcon
  }
  
  import IntMegaDropdown from './modules/mega-dropdown'
  import IntMobileNavigation from './modules/mobile-navigation'
  import IntNavButton from '../nav-button'
  import IntWishlistAccount from '../wishlists/modules/account'
  import IntWishlistModal from '../wishlists/modules/modal'
  import IntWishlistPopup from '../wishlists/modules/popup'
  import Logo from '../assets/icons/Logo.vue'
  
  export default {
    name: 'IntHeader',
  
    components: {
      IntMegaDropdown,
      IntMobileNavigation,
      IntNavButton,
      IntWishlistAccount,
      IntWishlistModal,
      IntWishlistPopup,
      Logo
    },
  
    props: {
      pages: { type: Array, required: true },
      homepageUrl: { type: String, default: '/' },
      searchPage: { type: String },
      searchLabel: { type: String, default: 'Search' },
      searchPlaceholder: { type: String, default: 'Enter keyword or product code' },
      contactPage: { type: String },
      contactLabel: { type: String, default: 'Contact us' },
      accountButtonLabel: { type: String, default: 'Login' },
      closeLabel: { type: String, default: 'Close' },
      accountTitle: { type: String, default: 'Trade Login' },
      loginPage: { type: String },
      loginPageTitle: { type: String, default: 'Log in' },
      accountLinks: { type: Array },
      accountFooterTitle: { type: String, default: 'Don\'t have a login?' },
      accountFooterPage: { type: String },
      accountFooterPageTitle: { type: String, default: 'Request trade login' },
      showWishlistPanel: { type: Boolean, default: false },
      wishlistListUrl: { type: String, default: '' },
      wishlistDetailUrl: { type: String, default: '' }
    },
  
    data() {
      return {
        isReduced: false,
        mobileMenuOpen: false,
        headerHeight: null
      }
    },
  
    mounted() {
      window.addEventListener('scroll', this.updateHeight)
      window.addEventListener('scroll', this.reduceHeaderPaddingOnScroll)
      
      this.updateHeight()
    },
  
    computed: {
      hasAccountLinks() {
        return this.accountLinks && this.accountLinks.length > 0
      },
  
      showAccountPanel() {
        return this.hasAccountLinks || this.loginPage || this.accountFooterPage
      }
    },
  
    methods: {
      closePopover(close) {
        close()
      },
  
      closeMenu() {
        this.mobileMenuOpen = false
      },
  
      updateHeight() {
        this.headerHeight = this.$refs.headerDiv.clientHeight
      },

      reduceHeaderPaddingOnScroll() {
        this.isReduced = window.scrollY > 0
      },
  
      iconHref(key) {
        if (iconMap[key]) return '#' + iconMap[key].id
        return null
      }
    }
  }
</script>