<template>
  <div class="p-2 px-5 uppercase">
    <div v-if="title" class="text-2xl font-thin mt-4 mb-3">{{ title }}</div>

    <ul :class="['flex flex-col gap-1', { 'mt-4' : !title }]">
      <li v-for="link in links">
        <a :href="link.url" :target="link.target" class="py-1 block hover:underline focus:underline">{{ link.name }}</a>
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    name: 'IntMobileNavigation',
  
    props: {
      links: { type: Array, required: true },
      title: { type: String }
    }
  }
</script>