import { defineStore } from 'pinia'

import Collection from '../models/Collection'
import Product from '../models/Product'
import ProductQuantity from '../models/ProductQuantity'
import Wishlist from '../models/Wishlist'
import WishlistUpdated from '../models/WishlistUpdated'

import ApiHelper from '../components/wishlists/utils/api-helper'
import EventBus from '../bus'
import Events from '../components/wishlists/events'

export const useWishlistStore = defineStore('wishlist', {
    state: () => ({
        _wishlists: new Map(),
        _products: new Map(),
        _collections: new Map()
    }),
    
    getters: {
        wishlists(state) {
            const allWishlists = Array.from(state._wishlists.values())
            return allWishlists.sort(
                (a, b) => b.created.getTime() - a.created.getTime()
            )
        },
        
        latestWishlists() {
            const allWishlists = this.wishlists
            return allWishlists.length > 2 ? allWishlists.slice(0, 2) : allWishlists
        },

        getWishlistById: (state) => (wishlistId) => {
            return state._wishlists.get(wishlistId)
        },

        products(state) {
            return Array.from(state._products.values())
        },
        
        collections(state) {
            return Array.from(state._collections.values())
        }
    },
    
    actions: {
        addWishlist(name) {
            const created = new Date()
            const wishlistId = created.getTime()
            
            this._wishlists.set(wishlistId, new Wishlist(wishlistId, name, created))
            
            ApiHelper.postWishlists(this._wishlists)
                .then()
                .catch(error => { console.error('Error saving wishlists: ', error) })
        },
        
        deleteWishlist(wishlistId) {
            if (this._wishlists.has(wishlistId)) {
                this._wishlists.delete(wishlistId)

                ApiHelper.postWishlists(this._wishlists)
                    .then()
                    .catch(error => { console.error('Error saving wishlists: ', error) })
            }
        },

        /**
         * Add products to a wishlist
         * @param {int} wishlistId - The ID of the wishlist.
         * @param {{}|{collectionId, quantity: number, productIds: *[]}}productsObject - Object containing array of productIds, collectionId and quantity.
         */
        addProducts(wishlistId, productsObject) {
            if (!productsObject || !productsObject.productIds) return
            
            const wishlist = this._wishlists.get(wishlistId)
            if (!wishlist) throw new Error('Could not find wishlist ' + wishlistId)
            
            // TODO: Optimisation - POST body - post productsObject array and let API handle extracting productIds and collectionId and return required data

            const collectionId = productsObject.collectionId
            const distinctProductIds = Array.from(new Set(productsObject.productIds))
            
            for (let i = 0; i < distinctProductIds.length; i++) {
                const productId = distinctProductIds[i]
                const existingProduct = wishlist.items.get(productId)

                // If the product already exists, don't adjust the quantity if adding a collection
                if (!existingProduct || (existingProduct && collectionId === 0)) {
                    // If product not in wishlist - add with quantity
                    // If adding single product already in wishlist - increment quantity
                    const newQuantity = existingProduct ? existingProduct.quantity + productsObject.quantity : productsObject.quantity
                    const newProductQuantity = new ProductQuantity(productId, collectionId, newQuantity)
                    wishlist.add(newProductQuantity)
                }
            }

            ApiHelper.postWishlists(this._wishlists)
                .then()
                .catch(error => { console.error('Error saving wishlists: ', error) })

            // Filter out the existing productIds from the products store
            const productIdsNotInStore = distinctProductIds.filter(productId => !this._products.has(productId))

            // Only get the product data for products not already in the store
            const productFetch = productIdsNotInStore.length > 0
                ? fetch(`/umbraco/api/wishlist/products/?productIds=${productIdsNotInStore.join(',')}`)
                    .then((response) => { return response.json() })
                    .then((json) => { return json.products })
                    .then(products => {
                        products.forEach(product => {
                            let newProduct = new Product(product.id, product.name, product.image, product.size)
                            this._products.set(Number(product.id), newProduct)
                        })
                    })
                : Promise.resolve()

            // Check if collection already in store
            const collectionIdAlreadyInStore = collectionId === 0 ? true : this._collections.has(collectionId)

            // Only get the collection data if collectionId not already in the store
            const collectionFetch = !collectionIdAlreadyInStore
                ? fetch(`/umbraco/api/wishlist/collection/${collectionId}`)
                    .then((response) => { return response.json() })
                    .then((json) => { return json.collection })
                    .then(collection => {
                        let newCollection = new Collection(collection.id, collection.name, collection.image)
                        this._collections.set(Number(collection.id), newCollection)
                    })
                : Promise.resolve()

            const fetchPromises = [productFetch, collectionFetch]

            Promise.all(fetchPromises.flat())
                .then(() => {
                    const wishlistUpdated = new WishlistUpdated(wishlistId, "add", distinctProductIds, collectionId)
                    EventBus.$emit(Events.wishlistUpdated, wishlistUpdated)
                })
                .catch(error => {
                    console.error('Error adding products:', error)
                })
        },

        /**
         * Update the quantity of an existing product in a wishlist.
         * @param {int} wishlistId - The ID of the wishlist.
         * @param {int} productId - The ID of the product.
         * @param {int} newQuantity - The new quantity for the product.
         */
        updateQuantity(wishlistId, productId, newQuantity) {
            if (!wishlistId && !productId && !newQuantity) return

            const wishlist = this._wishlists.get(wishlistId)
            if (!wishlist) throw new Error('Could not find wishlist ' + wishlistId)

            const existingProduct = wishlist.items.has(productId)

            if (!existingProduct) {
                console.error(`Error updating product ${productId} quantity to ${newQuantity}. Product does not exist in wishlist`)
                return
            }

            const newProductQuantity = new ProductQuantity(productId, 0, newQuantity)
            wishlist.add(newProductQuantity)

            ApiHelper.postWishlists(this._wishlists)
                .then()
                .catch(error => { console.error('Error saving wishlists: ', error) })
        },

        /**
         * Remove products from a wishlist.
         * @param {int} wishlistId - The ID of the wishlist.
         * @param {Array<int>} productIds - An array of products IDs to be removed.
         * @param {int} collectionId - The ID of the collection being removed.
         */
        removeProducts(wishlistId, productIds, collectionId) {
            if (!productIds || productIds.length === 0) return
            
            const wishlist = this._wishlists.get(wishlistId)
            if (!wishlist) throw new Error('Could not find wishlist ' + wishlistId)
            
            // Remove products from the wishlists
            for (let i = 0; i < productIds.length; i++) {
                const productId = productIds[i]
                wishlist.remove(productId)
            }

            ApiHelper.postWishlists(this._wishlists)
                .then()
                .catch(error => { console.error('Error saving wishlists: ', error) })

            // Filter out the existing productIds from the products store
            const productIdsNotInStore = productIds.filter(productId => !this._products.has(productId))

            // Only get the product data for products not already in the store
            const productFetch = productIdsNotInStore.length > 0
                ? fetch(`/umbraco/api/wishlist/products/?productIds=${productIdsNotInStore.join(',')}`)
                    .then((response) => { return response.json() })
                    .then((json) => { return json.products })
                    .then(products => {
                        products.forEach(product => {
                            let newProduct = new Product(product.id, product.name, product.image, product.size)
                            this._products.set(Number(product.id), newProduct)
                        })
                    })
                : Promise.resolve()

            // Check if collection already in store
            const collectionAlreadyInStore = collectionId === 0 ? true : this._collections.has(collectionId)

            // Only get the collection data if collection not already in the store
            const collectionFetch = !collectionAlreadyInStore
                ? fetch(`/umbraco/api/wishlist/collection/${collectionId}`)
                    .then((response) => { return response.json() })
                    .then((json) => { return json.collection })
                    .then(collection => {
                        let newCollection = new Collection(collection.id, collection.name, collection.image)
                        this._collections.set(Number(collection.id), newCollection)
                    })
                : Promise.resolve()
            
            const fetchPromises = [productFetch, collectionFetch]

            Promise.all(fetchPromises.flat())
                .then(() => {
                    const wishlistUpdated = new WishlistUpdated(wishlistId, "remove", productIds, collectionId)
                    EventBus.$emit(Events.wishlistUpdated, wishlistUpdated)
                })
                .catch(error => {
                    console.error('Error adding products:', error)
                })
        },

        /**
         * Check whether a wishlist contains all specified products.
         * @param {int} wishlistId - The ID of the wishlist.
         * @param {Array<int>} productIds - An array of products IDs to be checked.
         * @returns {*|boolean} - True if the wishlist contains all of the productIds, or false.
         */
        hasAllProducts(wishlistId, productIds) {
            if (!productIds || productIds.length === 0) return false

            const wishlist = this._wishlists.get(wishlistId)
            if (!wishlist) throw new Error('Could not find wishlist ' + wishlistId)

            if (wishlist.items.size === 0) return false

            return productIds.every(id => wishlist.items.has(id))
        },

        /**
         * Check whether a wishlist has any specified products.
         * @param {int} wishlistId - The ID of the wishlist.
         * @param {Array<int>} productIds - An array of products IDs to be checked.
         * @returns {*|boolean} - True if the wishlist contains any of the productIds, or false.
         */
        hasAnyProducts(wishlistId, productIds) {
            if (!productIds || productIds.length === 0) return false

            const wishlist = this._wishlists.get(wishlistId)
            if (!wishlist) throw new Error('Could not find wishlist ' + wishlistId)

            if (wishlist.items.size === 0) return false

            return productIds.some(id => wishlist.items.has(id))
        },

        /**
         * Check whether any wishlist contains all ProductIds.
         * @param {Array<int>} productIds - An array of products IDs to be checked.
         * @returns {boolean} - True if any wishlist contains all of the productIds, otherwise false.
         */
        anyWishlistHasAllProducts(productIds) {
            if (!productIds || productIds.length === 0) return false
            
            const wishlists = this.wishlists
            if (!wishlists || wishlists.length === 0) return false

            return Array.from(this.wishlists.values()).some((wishlist) => {
                return productIds.every((id) => wishlist.items.has(id))
            })
        },

        /**
         * Set a discount percentage value for a wishlist.
         * @param {int} wishlistId - The ID of the wishlist.
         * @param {decimal} discount - The discount percentage as a decimal.
         */
        setDiscount(wishlistId, discount) {
            const wishlist = this._wishlists.get(wishlistId)
            if (!wishlist) throw new Error('Could not find wishlist ' + wishlistId)

            wishlist.discount = discount
            
            ApiHelper.postWishlists(this._wishlists)
                .then()
                .catch(error => { console.error('Error saving wishlists: ', error) })
        }
    }
})