import { useWishlistStore } from '../scripts/stores/wishlist'
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { RegisterComponents } from './components'
import preloadWishlistHelper from '../scripts/components/wishlists/utils/preload-wishlists'

const pinia = createPinia()
const app = createApp({})

RegisterComponents(app)

app.use(pinia)

const wishlistStore = useWishlistStore(pinia) // pass the instance to our store, instead of inferring it from our Vue app

// Preload wishlist data into store
const appId = 'app'
const wishlistsMap = preloadWishlistHelper.preloadWistData(appId)
if (wishlistsMap) {
    wishlistStore.$state._wishlists = wishlistsMap
}

app.mount(document.getElementById(appId))