<template>
  <Popover v-slot="{ open }" class="inline-block group lg:relative">
    <Transition leave-active-class="transition-opacity duration-2000 ease-in" leave-from-class="opacity-100" leave-to-class="opacity-0">
      <PopoverPanel static v-if="show" v-slot="{ close }" class="absolute right-0 lg:-right-4 w-full flex flex-row justify-center items-center lg:w-auto lg:text-right mt-10 md:mt-14 lg:mt-7 px-6 lg:px-0 xl:-right-9">
        <div class="relative w-96 p-8 bg-white rounded-3xl shadow-md">
          <svg class="hidden -top-[1.25rem] lg:right-5 xl:right-10 lg:inline-block lg:absolute lg:group-data-[headlessui-state=open]:inline-block"
               xmlns="http://www.w3.org/2000/svg" width="38" height="22" viewBox="0 0 38 22">
            <defs>
              <filter id="Polygon_1" x="0" y="0" width="38" height="22" filterUnits="userSpaceOnUse">
                <feOffset dy="-2" input="SourceAlpha" />
                <feGaussianBlur stdDeviation="1" result="blur" />
                <feFlood flood-opacity="0.161" />
                <feComposite operator="in" in2="blur" />
                <feComposite in="SourceGraphic" />
              </filter>
            </defs>

            <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Polygon_1)">
              <path id="Polygon_1-2" data-name="Polygon 1" d="M16,0,32,16H0Z" transform="translate(3 5)" fill="#fff" />
            </g>
          </svg>

          <div class="border-b-[1px] text-left">
            <h5 class="normal-case">{{ actionText }} <span class="font-medium">'{{ wishlistName }}'</span></h5>
          </div>

          <IntWishlistPopupItem v-if="itemUpdated"
                                :name="itemUpdated.name" :image="itemUpdated.image" :size="itemUpdated.size">
          </IntWishlistPopupItem>
          
          <p v-if="moreItemsCount > 0" class="text-left text-sm mb-2">And {{ moreItemsCount }} more item{{ moreItemsCount !== 1 ? 's' : '' }}.</p>

          <div>
            <IntNavButton type="anchor" :href="wishlistDetailUrl + '?id=' + wishlistId" color="tint" fit-content class="uppercase text-center hover:no-underline focus:no-underline">
              {{ viewWishlistLabel }}
            </IntNavButton>
          </div>
        </div>
      </PopoverPanel>
    </Transition>
  </Popover>
</template>

<script setup>
  import { Popover, PopoverPanel } from '@headlessui/vue'
</script>

<script>
  import { mapState } from 'pinia'
  import { useWishlistStore } from '../../../../stores/wishlist'

  import EventBus from '../../../../bus'
  import Events from '../../events'
  
  import IntNavButton from '../../../nav-button/'
  import IntWishlistPopupItem from './item.vue'

  export default {
    name: 'IntWishlistPopup',
    
    components: { IntNavButton, IntWishlistPopupItem },
    
    props: {
      wishlistDetailUrl: { type: String, required: true },
      viewWishlistLabel: { type: String, default: 'View wishlist' },
      delayFadeOut: { type: Number, default: 5000 } // Duration to delay the fade out animation, in milliseconds
    },
    
    data() {
      return {
        show: false,
        itemUpdated: {},
        moreItemsCount: 0,
        actionText: '',
        wishlistName: '',
        wishlistId: null,
        fadeOutTimeout: null // Variable to store the timeout reference
      }
    },

    computed: {
      ...mapState(useWishlistStore, ['wishlists', 'products', 'collections'])
    },

    created() {
      EventBus.$on(Events.wishlistUpdated, this.onWishlistUpdatedEventDisplayPopup)
    },

    beforeDestroy() {
      clearTimeout(this.fadeOutTimeout)
    },
    
    methods: {
      onWishlistUpdatedEventDisplayPopup(e) {
        this.clearPreviousPopupData()
        
        if (e.action === "add") this.actionText = 'You added an item to the wishlist '
        else this.actionText = 'You removed an item from the wishlist '
        
        const wishlist = this.wishlists.find(wishlist => wishlist.id === e.id)
        
        if (e.collectionId) {
          const collection = this.collections.find(collection => collection.id === e.collectionId)
          if (collection) this.itemUpdated = collection
        } else {
          const productIds = e.productIds
          
          const firstProduct = this.products.find(product => product.id === productIds[0])
          if (firstProduct) {
            this.itemUpdated = firstProduct
            
            let moreItems = productIds.length - 1
            if (moreItems > 0) this.moreItemsCount = moreItems
          }
        }
        
        this.show = true
        this.wishlistId = wishlist.id
        this.wishlistName = wishlist.name
        
        // Cancel the previous timeout and start a new one
        clearTimeout(this.fadeOutTimeout)
        
        this.fadeOutTimeout = setTimeout(() => {
          this.show = false
          this.clearPreviousPopupData()
        }, this.delayFadeOut)
      },

      /**
       * Clear the data from the previous popup, in case another update within timeout period
       */
      clearPreviousPopupData() {
        this.itemUpdated = {}
        this.moreItemsCount = 0
        this.wishlistId = null
        this.wishlistName = ''
      }
    }
  }
</script>