<template>

</template>

<script>
export default {
    name: "IntUmbracoformsScriptLoader",

    mounted() {
        this.loadUmbracoFormsScript()
        this.loadRecaptchaV3Script()
    },
    
    methods: {
        _appendScript(scriptSrc) {
            let scriptEl = document.createElement('script')
            scriptEl.setAttribute('src', scriptSrc)

            const bodyEl = document.querySelector('body')
            bodyEl.appendChild(scriptEl)
        },
        
        loadUmbracoFormsScript() {
            const src = '/App_Plugins/UmbracoForms/Assets/themes/default/umbracoforms.min.js'
            this._appendScript(src)
        },
        loadRecaptchaV3Script() {
            const src = '/App_Plugins/Intuitiv/UmbracoForms/recaptcha.v3.init.js'
            this._appendScript(src)
        }
    }
}
</script>