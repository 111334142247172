<template>
    <footer class="bg-stone-300 pt-24">
        <div class="2xl:container mx-auto px-5 lg:px-8 xl:px-10 mb-0 lg:mb-3">
            <div class="grid grid-flow-row grid-rows-1 grid-cols-12">
                <div class="col-span-12">
                    <div class="pb-10">
                        <svg xmlns="http://www.w3.org/2000/svg" width="84" fill="#2F2F2F" height="30">
                            <use :href="iconHref('Logo')" />
                        </svg>
                    </div>
                </div>
              
                <div class="col-span-12 md:col-span-7">
                    <IntAddress :address-lines="addressLines" :phone="phone" :email="email" :social-links="socialLinks"></IntAddress>

                    <div v-if="hasPromoLinks" class="mt-10 hidden md:block">
                        <span v-for="(link, index) in promoLinks" :key="'promolinkdesktop' + index" class="inline">
                          <int-nav-button type="anchor" :href="link.url" :target="link.target" class="border-2 mr-4 mb-4 uppercase text-center hover:no-underline focus:no-underline" 
                                          color="inversed">
                            {{ link.name }}
                          </int-nav-button>
                        </span>
                    </div>
                </div>

                <div class="col-span-12 md:col-span-5">
                    <div class="grid grid-flow-row grid-rows-1 grid-cols-12">
                        <div v-if="hasCompanyLinks" class="col-span-12 lg:col-span-6">
                            <ul class="text-xs md:text-sm uppercase leading-10 md:leading-9">
                                <li v-for="(link, index) in companyLinks" :key="'companylink' + index">
                                  <a :href="link.url" :target="link.target">{{ link.name }}</a>
                                </li>
                            </ul>
                        </div>
                      
                        <div v-if="hasProductLinks" class="col-span-12 lg:col-span-6">
                            <ul class="text-xs md:text-sm uppercase leading-10 md:leading-9">
                              <li v-for="(link, index) in productLinks" :key="'productlink' + index">
                                <a :href="link.url" :target="link.target">{{ link.name }}</a>
                              </li>
                            </ul>
                        </div>

                        <div v-if="hasPromoLinks" class="col-span-12 md:hidden mt-10">
                          <span v-for="(link, index) in promoLinks" :key="'promolinkmobile' + index" class="inline mb-2">
                              <int-nav-button 
                                  type="anchor" :href="link.url" :target="link.target" class="border-2 min-w-full xs:min-w-0 mr-3 mb-4 uppercase text-center hover:no-underline focus:no-underline" 
                                  color="inversed">
                                {{ link.name }}
                              </int-nav-button>
                          </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="legalText || hasLegalLinks" class="bg-stone py-10 text-xs md:text-sm mt-10 lg:mt-20">
            <div class="2xl:container mx-auto px-5 lg:px-8 xl:px-10 mb-0 lg:mb-3">
                <div class="grid grid-flow-row grid-rows-1">
                    <div class="col-span-12 grid lg:block">
                        <span v-if="legalText" class="mr-10 order-last lg:order-first uppercase">{{ legalText }}</span>
                        <span v-if="hasLegalLinks">
                          <span v-for="(link, index) in legalLinks" :key="'legallink' + index" class="block mb-5 uppercase lg:inline-block lg:mb-0">
                            <a :href="link.url" :target="link.target" class="link mr-5">{{ link.name }}</a>
                          </span>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
  import Logo from '../../../assets/icons/logomark.svg'
  
  import IntAddress from '../address'
  import IntNavButton from '../nav-button'
  
  const iconMap = {
      'Logo': Logo
  }
  
  export default {
      components: { IntAddress, IntNavButton },
  
      name: 'IntFooter',
    
      props: {
          addressLines: { type: Array },
          phone: { type: String },
          email: { type: String },
          socialLinks: { type: Array },
          promoLinks: { type: Array },
          companyLinks: { type: Array },
          productLinks: { type: Array },
          legalText: { type: String },
          legalLinks: { type: Array }
      },

      computed: {
        hasPromoLinks() {
          return this.promoLinks && this.promoLinks.length > 0
        },
        
        hasCompanyLinks() {
          return this.companyLinks && this.companyLinks.length > 0
        },
        
        hasProductLinks() {
          return this.productLinks && this.productLinks.length > 0
        },

        hasLegalLinks() {
          return this.legalLinks && this.legalLinks.length > 0
        }
      },
    
      methods: {
          iconHref(key) {
              if (iconMap[key]) return '#' + iconMap[key].id
            
              return null
          }
      }
  }
</script>