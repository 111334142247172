import { isValidDecimal } from '../utils'

export default class Wishlist {
    constructor(id, name, created, items, discount) {
        this.id = id
        this.name = name
        this.created = new Date(created)
        this.items = _initItemsMap(items)
        this.discount = _initDiscount(discount)
    }
    
    add(productQuantity) {
        this.items.set(productQuantity.productId, productQuantity)
    }
    
    remove(productId) {
        if (this.items.has(productId)) {
            this.items.delete(productId)
        }
    }
}

const _isValidItemMap = (items) => {
    return items && Array.isArray(items)
}
const _initItemsMap = (items) => {
    if (!items) return new Map()
    
    if (_isValidItemMap(items) === false) throw new Error('Invalid wishlist items format')
    
    return new Map(items)
}

const _initDiscount = (discount) => {
    if (!discount) return null

    if (isValidDecimal(discount) === false) throw new Error('Invalid Wishlist discount format')

    return discount
}