/**
 * Create a ProductQuantity object to be emitted when adding or removing products.
 * @param {Array<int>}productIds - An array of products IDs.
 * @param {int}collectionId - A Collection ID indicating the products being added are a collection.
 * @param {int} quantity - The quantity to set. If adding a collection, the quantity is forced to 1.
 * @returns {{}|{collectionId, quantity: number, productIds: *[]}} - An object of productIds with collection and quantity, or an empty object.
 */
const createProductQuantityEvent = (productIds, collectionId, quantity) => {
    if (!productIds || productIds.length === 0) return {}

    if (!quantity || collectionId > 0) quantity = 1

    return { productIds: [...productIds], collectionId: collectionId, quantity: quantity }
}

export default {
    createProductQuantityEvent
}