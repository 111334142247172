<template>
    <section :class="['w-full', { 'pt-8 md:pt-12 lg:pt-16' : paddingTop }, { 'pb-8 md:pb-12 lg:pb-16' : paddingBottom }, cssBackgroundClass]">
        <slot></slot>
    </section>
</template>

<script>
  export default {
      name: 'IntContentSection',
    
      props: {
          paddingTop: { type: Boolean, default: true },
          paddingBottom: { type: Boolean, default: true },
          background: {
            type: String,
            default: '',
            validator(value) {
              return ['', 'white', 'gray', 'black'].includes(value)
            }
          }
      },
    
      computed: {
          cssBackgroundClass() {
              switch (this.background)
              {
                  case 'black': return 'bg-black/50'
                  case 'gray': return 'bg-gray-100'
                  case 'white': return 'bg-white'
                  default: return ''
              }
          }
      }
  }
</script>