<template>
  <div class="relative col-span-6 col-start-7 before:absolute before:w-full before:h-full before:bg-gradient-to-t before:from-black before:to-50% before:opacity-20 before:z-10">
    <IntResponsiveImage :src="imageUrl"
                        :width="460" :ratio="0.55" :min-width="460" :max-width="780"
                        :generate-crop-src="true" :generate-crop-src-set="true" mode="crop"
                        :full-width="true"
                        class="relative min-h-full object-cover" />

    <div :class="['absolute bottom-0 z-20 w-full p-5 flex items-end', { 'justify-end' : !title && !text }]">
      <div v-if="title || text" class="grow pr-5">
        <h4 v-if="title" class="text-white text-4xl drop-shadow-md">{{ title }}</h4>

        <span v-if="text" class="text-white drop-shadow-md">{{ text }}</span>
      </div>

      <div>
        <IntNavButton type="anchor" :href="link.url" :target="link.target" :white="true" class="hover:no-underline focus:no-underline uppercase">
          {{ link.name }}
        </IntNavButton>
      </div>
    </div>
  </div>
</template>

<script>
  import IntNavButton from '../../../nav-button'
  import IntResponsiveImage from '../../../responsive-image'
  
  export default {
    name: 'IntNavigationPromo',
    
    components: { IntNavButton, IntResponsiveImage },
    
    props: {
      imageUrl: { type: String, required: true },
      link: { type: Object, required: true },
      title: { type: String },
      text: { type: String }
    }
  }
</script>