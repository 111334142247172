<template>
  <div class="2xl:container mx-auto px-5 lg:px-8 xl:px-10 mb-0 lg:mb-3 text-left">
    <div class="grid grid-cols-12 gap-4">
      <IntSubNav v-for="subNav in subNavigations"
                 :title="subNav.title" 
                 :links="subNav.links">
      </IntSubNav>
      
      <IntNavPromo v-for="promo in promos" 
                   :image-url="promo.imageUrl" 
                   :link="promo.link" 
                   :title="promo.title" 
                   :text="promo.text">
      </IntNavPromo>
    </div>
  </div>
</template>

<script>
  import IntNavButton from '../../../nav-button'
  import IntNavPromo from '../navigation-promo'
  import IntSubNav from '../sub-navigation'

  export default {
    name: 'IntMegaDropdown',

    components: { IntNavButton, IntNavPromo, IntSubNav },
    
    props: {
      subNavigations: { type: Array },
      promos: { type: Array }
    }
  }
</script>