<template>
  <div class="flex my-5 text-sm">
    <div v-if="image" class="mr-5">
      <IntResponsiveImage :src="image" :width="50" :height="50" generate-crop-src mode="BoxPad" sizes="50px"></IntResponsiveImage>
    </div>
    
    <div class="text-left">
      <div class="font-medium">{{ name }}</div>
      <div v-if="size">{{ size }}</div>
    </div>
  </div>
</template>

<script>
  import IntResponsiveImage from '../../../responsive-image'
  
  export default {
    name: 'IntWishlistPopupItem',
    
    components: { IntResponsiveImage },
    
    props: {
      name: { type: String, required: true },
      image: { type: String },
      size: { type: String }
    }
  }
</script>