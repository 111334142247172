export default class WishlistUpdated {
    constructor(id, action, productIds, collectionId) {
        this.id = id
        this.action = action
        this.productIds = _initProductIdsArray(productIds)
        this.collectionId = _initCollectionId(collectionId)
    }
}

const _isValidArray = (array) => {
    return array && Array.isArray(array)
}

const _isValidNumber = (number) => {
    return number && Number.isInteger(number)
}

const _initProductIdsArray = (productIds) => {
    if (!productIds) return []

    if (_isValidArray(productIds) === false) throw new Error('Invalid WishlistUpdated productIds format')

    return productIds
}

const _initCollectionId = (collectionId) => {
    if (!collectionId) return 0

    if (_isValidNumber(collectionId) === false) throw new Error('Invalid WishlistUpdated collectionId format')

    return collectionId
}